<template>
  <v-dialog
    v-model="dialog"
    fullscreen hide-overlay
    transition="dialog-bottom-transition"
    >
    <template v-slot:activator="{on}">
      <v-btn text small v-on="on">
        <v-icon>edit</v-icon> {{translate('Create new user', 'users.add')}}
      </v-btn>
    </template>
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>{{translate('Create new user', 'users.add')}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
          <v-btn
            text
            primary
            :disabled="!validForm"
            @click="createUser">
            <v-icon>save</v-icon>
            {{translate('Create', 'users.create')}}
          </v-btn>
          <v-btn text icon @click="dialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <v-form ref="form" v-model="validForm">
          <v-select
            v-model="provider"
            :items="providerNames"
            :label="translate('Issuer', 'authentication.issuer')"
            :required="true"
            :rules="validationRules.listValue()"
            />
          <v-text-field
            type="email"
            :rules="validationRules.email()"
            :required="true"
            v-model="email"
            :label="translate('Email', 'email')"
            autocomplete="off" />
          <v-text-field type="text" v-model="description" :label="translate('Description', 'description')" autocomplete="off" />
          <v-checkbox
            v-for="role in roleNames"
            :key="role"
            :label="role"
            v-model="roles[role]"
            >{{role}}
          </v-checkbox>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>

import {mapGetters} from 'vuex'
export default {
  props: ['roleNames', 'providerNames'],
  data: () => ({
    dialog: false,
    validForm: true,
    provider: '',
    email: '',
    description: '',
    roles: {}
  }),
  computed: {
    ...mapGetters(['translate', 'validationRules']),
  },
  methods: {
    createUser () {
      this.$emit('create-user', {
        provider: this.provider,
        email: this.email,
        description: this.description,
        roles: Object.entries(this.roles).filter(([, enabled]) => enabled).map(([role]) => role)
      })
      this.dialog = false
    }
  }
}
</script>
