<template>
  <layout-page>
    <template v-slot:toolbar-title>
      <v-toolbar-title>{{translate('Users')}}</v-toolbar-title>
    </template>
    <template v-slot:toolbar-items>
      <v-toolbar-items>
        <create-user-dialog
          :roleNames="users.roles"
          :providerNames="users.providers"
          @create-user="createUser"
        />
      </v-toolbar-items>
    </template>

    <v-card>
      <v-card-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :loading="users.loading"
          :headers="headers"
          :items="users.users"
          :search="search">
          <template v-slot:body="{items}">
            <tbody>
              <tr v-for="user in items" :key="user.email">
                <td>
                  <edit-user-dialog
                    :user="user"
                    :roleNames="users.roles"
                    @update-user="updateUser"
                    />
                </td>
                <td>{{user.email}}</td>
                <td>{{user.description}}</td>
                <td>
                  <v-chip v-for="role in user.roles" :key="role">
                    {{role}}
                  </v-chip>
                </td>
                <td>{{user.provider}}</td>
                <td>
                  <delete-user-dialog
                    v-if="user.id !== authenticatedUser.id"
                    :user="user"
                    @delete-user="deleteUser"
                    />
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </layout-page>
</template>
<script>
import {mapGetters} from 'vuex'
import LayoutPage from '@/components/layout/layout-page.vue'
import CreateUserDialog from './create-user-dialog'
import DeleteUserDialog from './delete-user-dialog'
import EditUserDialog from './edit-user-dialog'

export default {
  components: {
    LayoutPage,
    CreateUserDialog,
    DeleteUserDialog,
    EditUserDialog
  },
  data: () => ({
    search: "",
    mutations: 1
  }),
  created () {
  },
  computed: {
    ...mapGetters(['api', 'translate', 'authenticatedUser']),
    headers () {
      return [{
        text: '',
        sortable: false,
        value: ''
      }, {
        text: this.translate('Email', 'email'),
        sortable: true,
        value: 'email'
      }, {
        text: this.translate('Description', 'description'),
        sortable: true,
        value: 'description'
      }, {
        text: this.translate('Roles', 'roles'),
        sortable: false,
        value: 'roles'
      }, {
        text: this.translate('Issuer', 'authentication.issuer'),
        sortable: true,
        value: 'provider'
      }, {
        text: '',
        sortable: false,
        value: ''
      }]
    },
  },
  asyncComputed: {
    users: {
      get () {
        return this.mutations && this.api.users.getUsers()
      },
      default: {
        loading: true,
        roles: [],
        users: [],
        providers: []
      }
    }
  },
  methods: {
    async createUser (user) {
      await this.api.users.createUser(user)
      this.mutations = this.mutations + 1
    },
    async updateUser (user) {
      await this.api.users.updateUser(user)
      this.mutations = this.mutations + 1
    },
    async deleteUser (user) {
      await this.api.users.deleteUser(user)
      this.mutations = this.mutations + 1
    }

  }
}
</script>
