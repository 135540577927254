<template>
  <confirm-button>
    <template v-slot:button="confirm">
      <v-btn text small icon color="warning" @click="confirm">
        <v-icon>delete</v-icon>
      </v-btn>
    </template>
    <template v-slot:confirm="cancel">
      <v-item-group multiple>
        <v-btn text small color="error" @click="deleteUser()">
          <v-icon>delete</v-icon> {{translate('Delete this user?', 'user.delete')}}
        </v-btn>
        <v-btn text small icon @click="cancel">
          <v-icon>cancel</v-icon>
        </v-btn>
      </v-item-group>
    </template>
  </confirm-button>
</template>
<script>
import {mapGetters} from 'vuex'

import ConfirmButton from '@/components/confirm-button'

export default {
  props: ['user'],
  components: {
    ConfirmButton
  },
  computed: {
    ...mapGetters(['translate'])
  },
  methods: {
    deleteUser() {
      this.$emit('delete-user', this.user)
    }
  }

}
</script>
