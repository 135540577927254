<template>
  <v-dialog
    v-model="dialog"
    fullscreen hide-overlay
    transition="dialog-bottom-transition"
    >
    <template v-slot:activator="{on}">
      <v-btn text small color="primary" v-on="on">
        <v-icon>edit</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>{{translate('Edit user', 'users.edit')}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
          <v-btn text primary @click="updateUser">{{translate('Save', 'users.save')}}</v-btn>
          <v-btn text icon @click="dialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <v-form>
            <v-text-field type="email" readonly v-model="editUser.email" :label="translate('Email', 'email')" autocomplete="off" />
            <v-text-field type="text" v-model="editUser.description" :label="translate('Description', 'description')" autocomplete="off" />
            <v-checkbox
              v-for="role in roleNames"
              :key="role"
              :label="role"
              v-model="editUser.roles[role]"
              >{{role}}</v-checkbox>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import {mapGetters} from 'vuex'

const toMap = (list, keyFn, valueFn) => list.reduce((memo, item) => {
  memo[keyFn(item)] = valueFn(item)
  return memo
}, {})

export default {
  props: ['user', 'roleNames'],
  data: () => ({
    dialog: false,
    editUser: {id: '', email: '', description: '', roles: {}}
  }),
  mounted () {
    this.editUser = {
      id: this.user.id,
      provider: this.user.provider,
      email: this.user.email,
      description: this.user.description,
      roles: toMap(this.roleNames, role => role, role => this.user.roles.includes(role))
    }
  },
  computed: {
    ...mapGetters(['translate']),
  },
  methods: {
    updateUser() {
      this.$emit('update-user', {
        ...this.editUser,
        roles: Object.entries(this.editUser.roles).filter(([, enabled]) => enabled).map(([role]) => role)
      })
      this.dialog = false
    }
  }
}
</script>
